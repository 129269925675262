import { useEffect, useRef, useState } from "react";

import {
  Card,
  CardContent,
  Divider,
  Grid,
  FormLabel,
  FormControl,
  Button,
  CardActions,
  TextField,
  Typography,
  Modal,
  AppBar,
} from "@mui/material";
import { useSnackbar } from "notistack";
import _, { replace } from "lodash";
import isUrl from "is-url";

import CustomBreadcrumbs from "components/Breadcrumbs";
import ImageUploader, { ImageUploader1 } from "components/Inputs/ImageUploader";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";
import Loader from "components/Loader";
import PreviewModal from "components/Template/PreviewModal";

const Template = ({ breadcrumbs }) => {
  const [logo, setLogo] = useState([]);
  const [banner, setBanner] = useState([]);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [color1, setColor1] = useState("#ffffff");
  const [color2, setColor2] = useState("#000000");
  const [color3, setColor3] = useState("#ffffff");
  const [color4, setColor4] = useState("#000000");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const handlePreviewModal = () => {
    setIsPreviewModalOpen(!isPreviewModalOpen);
  };
  const handleColor1Change = (event) => {
    setColor1(event.target.value);
  };

  const handleColor2Change = (event) => {
    setColor2(event.target.value);
  };
  const handleColor3Change = (event) => {
    setColor3(event.target.value);
  };

  const handleColor4Change = (event) => {
    setColor4(event.target.value);
  };

  const getWebDetails = async () => {
    setLoader(true);
    let res = await axiosInstance.get(
      APIRouteConstants.WEBSITE.WEB_LOGO_BANNER
    );
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (data) {
      if (data["logo"]) setLogo([data["logo"]]);
      if (data["banner"]) setBanner([data["banner"]]);
      if (data["header_bg"]) setColor1([data["header_bg"]]);
      if (data["header_text"]) setColor2([data["header_text"]]);
      if (data["footer_bg"]) setColor3([data["footer_bg"]]);
      if (data["footer_text"]) setColor4([data["footer_text"]]);
    }
    setLoader(false);
  };

  const updateWebDetail = async () => {
    // if (_.isEmpty(logo)) {
    //     enqueueSnackbar('Logo is mandatory', { variant: 'error' });
    //     return;
    // }

    setLoader(true);
    let formData = new FormData();
    if (logo[0] && !isUrl(logo[0])) {
      formData.append("logo", logo[0], logo[0]?.fileName);
    } else if (logo[0]) {
      formData.append("logo", logo[0]);
    }
    // else{ formData.append('logo',"")}
    if (banner[0] && !isUrl(banner[0]))
      formData.append("banner", banner[0], banner[0]?.fileName);
    if (color1 != "") {
      formData.append("header_bg", color1);
    }
    if (color2 != "") {
      formData.append("header_text", color2);
    }
    if (color3 != "") {
      formData.append("footer_bg", color3);
    }
    if (color4 != "") {
      formData.append("footer_text", color4);
    }
    const res = await axiosInstance({
      method: "post",
      url: APIRouteConstants.WEBSITE.WEB_LOGO_BANNER,
      data: formData,
    });
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;
    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }
    if (!_.isEmpty(data)) {
      enqueueSnackbar("Details updated successfully", { variant: "success" });
    }
    setLoader(false);
  };

  useEffect(() => {
    getWebDetails();
  }, []);

  const imageUploaderRef = useRef();
  const triggerImageUpload = () => {
    if (imageUploaderRef.current) {
      imageUploaderRef.current(); // This triggers the file input in ImageUploader
    }
  };
  const imageUploaderRef2 = useRef();
  const triggerImageUpload2 = () => {
    if (imageUploaderRef2.current) {
      imageUploaderRef2.current(); // This triggers the file input in ImageUploader
    }
  };
  return (
    <div className="store-analytics-wrapper">
      <Loader open={loader} />
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs list={breadcrumbs} name={"Website Template"} />
      </div>
      <Grid container className="component-grid">
        <Grid item xs={12}>
          <Card className="dashboard-card-wrapper">
            <CardContent>
              <div className="header-wrapper">
                <span className="header-text">
                  Build and manage your website
                </span>
              </div>
              <Divider />
              <div className="content-wrapper">
                <span className="content-text">
                  Using our Quick store method, you will be able to easily build
                  your website within minutes that works perfectly on desktop
                  and mobile. We will soon be releasing the ability to build
                  your own template using a drag and drop editor as well as
                  building your website using multiple pre-designed templates.
                </span>
                <br />
                <br />
                <span className="content-text">
                  Add your logo to your website and customise the colours on
                  your store template here.
                </span>
              </div>
              <Grid
                container
                className="dashboard-form-container"
                spacing={4}
                wrap="wrap"
              >
                <Grid item md={6} sm={12} xs={12}>
                  <div className="form-wrapper">
                    <FormLabel
                      className="form-label"
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <span className="label-text">
                          Upload your logo (optional)
                        </span>
                        <Button
                          onClick={triggerImageUpload}
                          size="small"
                          sx={{ p: 0, pl: 1, pr: 1, textTransform: "none" }}
                          variant="contained"
                          color="secondary"
                        >
                          Upload
                        </Button>
                      </div>
                      <span className="label-sec-text">
                        This is the logo we will display for your online store.
                      <br/>
                      <span className="label-sec-text">
                      Recommended size: 1024 x 1024 pixels
                      </span>
                      </span>
                    </FormLabel>

                    <FormControl className="form-control">
                      <ImageUploader1
                        message={"Recommended size: 1024 x 1024 pixels"}
                        imageList={logo}
                        setImageList={setLogo}
                        replaceImage={(callback) =>
                          (imageUploaderRef.current = callback)
                        }
                        id="logo"
                        name="logo"
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <div className="form-wrapper">
                    {/* <FormLabel className="form-label" >
                      <span className="label-text">
                        Upload a custom banner (optional)
                      </span>
                      <Button
                          onClick={triggerImageUpload}
                          size="small"
                          sx={{ p: 0, pl: 1, pr: 1, textTransform: "none" }}
                          variant="contained"
                          color="secondary"
                        >
                          Upload
                        </Button>
                      <br />
                      <span className="label-sec-text">
                        Design and upload a banner for your Shop page.
                      </span>
                    </FormLabel> */}
                    <FormLabel
                      className="form-label"
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <span className="label-text">
                          Upload a custom banner (optional)
                        </span>
                        <Button
                          onClick={triggerImageUpload2}
                          size="small"
                          sx={{ p: 0, pl: 1, pr: 1, textTransform: "none" }}
                          variant="contained"
                          color="secondary"
                        >
                          Upload
                        </Button>
                      </div>
                      <span className="label-sec-text">
                        Design and upload a banner for your Shop page.
                      <br/>
                      <span className="label-sec-text">
                      Recommended size: 3840 x 2160 pixels
                      </span>
                      </span>
                    </FormLabel>
                    <FormControl className="form-control">
                      {/* <ImageUploader
                        id="banner"
                        name="banner"
                        imageList={banner}
                        setImageList={setBanner}
                      /> */}
                      <ImageUploader1
                        message={"Recommended size: 3840 x 2160 pixels"}
                        id="banner"
                        name="banner"
                        imageList={banner}
                        setImageList={setBanner}
                        replaceImage={(callback) =>
                          (imageUploaderRef2.current = callback)
                        }
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                className="dashboard-form-container"
                spacing={4}
                wrap="wrap"
              >
                <Grid item md={6} sm={12} xs={12}>
                  <div className="form-wrapper">
                    <FormLabel className="form-label">
                      <span className="label-text">
                        Select your Header background and text color.
                      </span>
                      <br />
                      <span className="label-sec-text">
                        This is the Header background & text color we will
                        display for your online store.
                      </span>
                    </FormLabel>
                    <FormControl
                      className="form-control"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ marginBottom: "16px", width: "30%" }}>
                        {/* <Typography pb={2} style={{ fontSize: "14px" }}>
                          Selected Color: {color1}
                        </Typography> */}
                        <TextField
                          fullWidth
                          label="Background"
                          type="color"
                          value={color1}
                          onChange={handleColor1Change}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div style={{ width: "30%" }}>
                        {/* <Typography pb={2} style={{ fontSize: "14px" }}>
                          Selected Color: {color2}
                        </Typography> */}
                        <TextField
                          fullWidth
                          label="Text"
                          type="color"
                          value={color2}
                          onChange={handleColor2Change}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <div className="form-wrapper">
                    <FormLabel className="form-label">
                      <span className="label-text">
                        Select your Footer background and text color.
                      </span>
                      <br />
                      <span className="label-sec-text">
                        This is the Footer background & text color we will
                        display for your online store.
                      </span>
                    </FormLabel>
                    <FormControl className="form-control">
                      <FormControl
                        className="form-control"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginBottom: "16px", width: "30%" }}>
                          {/* <Typography pb={2} style={{ fontSize: "14px" }}>
                            Selected Color: {color3}
                          </Typography> */}
                          <TextField
                            fullWidth
                            label="Background"
                            type="color"
                            value={color3}
                            onChange={handleColor3Change}
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div style={{ width: "30%" }}>
                          {/* <Typography pb={2} style={{ fontSize: "14px" }}>
                            Selected Color: {color4}
                          </Typography> */}
                          <TextField
                            fullWidth
                            label="Text"
                            type="color"
                            value={color4}
                            onChange={handleColor4Change}
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                      </FormControl>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Divider />
            </CardContent>
            <CardActions className="footer-wrapper">
              <div className="action-wrapper">
                <Button
                  variant="contained"
                  onClick={handlePreviewModal}
                  color="secondary"
                >
                  Preview
                </Button>
              </div>
              <div className="action-wrapper">
                <Button
                  variant="contained"
                  onClick={updateWebDetail}
                  color="secondary"
                >
                  Update
                </Button>
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <PreviewModal
        headerbg={color1}
        headertext={color2}
        footerbg={color3}
        footertext={color4}
        isPreviewModalOpen={isPreviewModalOpen}
        handleClose={handlePreviewModal}
      />
    </div>
  );
};

export default Template;

import { useState } from 'react';
import {
    Card,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControl,
    TextField,
    InputAdornment,
    CardActions,
    CardContent,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from 'notistack';
import Loader from 'components/Loader';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";

import ResponsiveDatePickers from 'components/Inputs/DatePicker';
import { GLOBAL_CURRENCY } from 'constants/appConstants';
import OTP from 'components/Inputs/OTP';
import { useSelector } from 'react-redux';

const NewVoucher = ({ discardNewVoucher, voucher, isNew }) => {
    const loggedInUser = useSelector(state => state.userDetails);
    const { user } = loggedInUser;
    const [voucherOption, setVoucherOption] = useState(1);
    const { register, formState: { errors }, handleSubmit, control } = useForm();
    const [loader, setLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [openOTPModal, showOTPModal] = useState(false);
    const [otp, setOTP] = useState('');

    const addVoucher = async (formData) => {
        // formData = {
        //     ...formData,
        //     otp: otp,
        //     start_date: moment(formData.start_date).format("YYYY-MM-DD"),
        //     end_date: moment(formData.end_date).format("YYYY-MM-DD")
        // }
        // setLoader(true);
        // let res = await axiosInstance.post(APIRouteConstants.STORE.ADD_VOUCHER, formData)
        // if (res === false) {
        //     setLoader(false);
        //     return
        // }
        // let { data, response } = res;

        console.log("Before formatting:", {
            start_date: formData.start_date,
            end_date: formData.end_date,
        });

        formData = {
            ...formData,
            otp: otp,
            // start_date: moment.utc(formData.start_date).format("YYYY-MM-DD"),
            // end_date: moment.utc(formData.end_date).format("YYYY-MM-DD")
            start_date: moment(new Date(formData.start_date)).format("YYYY-MM-DD"),
            end_date: moment(new Date(formData.end_date)).format("YYYY-MM-DD")
            // start_date: moment(formData.start_date).format("YYYY-MM-DD"),
            // end_date: moment(formData.end_date).format("YYYY-MM-DD")
        }

        console.log("After formatting:", {
            start_date: formData.start_date,
            end_date: formData.end_date,
        });

        setLoader(true);
        let res = await axiosInstance.post(APIRouteConstants.STORE.ADD_VOUCHER, formData)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        // console.log('Response ', data, response)

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }

        if (data) {
            enqueueSnackbar('Voucher added successfully', { variant: 'success' });
            discardNewVoucher(false);
        }
        setLoader(false);
    }

    const sendOTP = async (formData) => {
        setLoader(true);
        let res = await axiosInstance.post(APIRouteConstants.STORE.SEND_OTP_VOUCHER, {
            email: user.email
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        console.log('Response ', data, response)

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }

        if (data) {
            enqueueSnackbar('OTP sent to your registered email address', { variant: 'success' });
            showOTPModal(true)
            setOTP('')
        }
        setLoader(false);
    }

    const updateVoucher = async (formData) => {
        formData = {
            ...formData,
            otp: otp,
            start_date: moment(formData.start_date).format("YYYY-MM-DD"),
            end_date: moment(formData.end_date).format("YYYY-MM-DD")
        }
        setLoader(true);
        let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_VOUCHER(voucher.id), formData)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }

        if (data) {
            enqueueSnackbar('Voucher updated successfully', { variant: 'success' });
            discardNewVoucher(false);
        }
        setLoader(false);
    }

    // const handleVoucherSubmit = (formData) => {
    //     if (voucher && voucher.id) updateVoucher(formData)
    //     else addVoucher(formData)
    // }
    const handleVoucherSubmit = (formData) => {
        console.log("Form Data Before Submit:", formData);
        console.log(control.getFieldState('start_date'));
        console.log(control.getFieldState('end_date'));
        if (voucher && voucher.id) updateVoucher(formData)
        else addVoucher(formData)
    }

    return (
        <Grid container className='component-grid'>
            <Loader open={loader} />
            <Grid item xs={12}>
                <form onSubmit={handleSubmit(handleVoucherSubmit)}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Vouchers {`>`} {voucher ? 'Edit' : 'New'} Voucher</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                    Fill the form below to create discount vouchers.
                                </span>
                            </div>
                            <Grid container className='dashboard-form-container' spacing={4} wrap='wrap'>
                                {/* <Add /> */}
                                <Grid item md={6} sm={12} xs={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Voucher name
                                            </span><br />
                                            <span className='label-sec-text'>
                                                This is the internal name that only you will see i.e 15%off.
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <TextField
                                                margin="dense"
                                                id="name"
                                                placeholder="Voucher Name"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                defaultValue={voucher?.name}
                                                {...register("name", { required: true })}
                                                error={errors.name?.type}
                                                helperText={errors.name?.type === "required" && (
                                                    'Voucher name is required'
                                                )}
                                                InputLabelProps={{ shrink: false }}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Voucher code
                                            </span><br />
                                            <span className='label-sec-text'>
                                                The code your customers will use, it can be letters +/numbers
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <TextField
                                                margin="dense"
                                                id="name"
                                                placeholder="Voucher Code"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                defaultValue={voucher?.code}
                                                {...register("code", { required: true })}
                                                error={errors.code?.type}
                                                helperText={errors.code?.type === "required" && (
                                                    'Voucher code is required'
                                                )}
                                                InputLabelProps={{ shrink: false }}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Voucher option
                                            </span><br />
                                            <span className='label-sec-text'>
                                                Select either percentage or value discount.
                                            </span>
                                        </FormLabel>
                                        <FormControl fullWidth className='select-wrapper'>
                                            <Controller
                                                control={control}
                                                name="voucher_type"
                                                rules={{ required: true }}
                                                defaultValue={voucher?.voucher_type}
                                                render={({ field: { onChange, onBlur, value } }) => (
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue={voucher?.voucher_type}
                                                        onChange={e => {
                                                            setVoucherOption(e.target.value)
                                                            onChange(e.target.value)
                                                        }}
                                                        error={errors.voucher_type?.type}
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        <MenuItem value={1}>%</MenuItem>
                                                        <MenuItem value={2}>{GLOBAL_CURRENCY.symbol}</MenuItem>
                                                    </Select>
                                                )}
                                            />
                                            {errors.voucher_type && <span className="error-text">Voucher Option is required.</span>}
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Discount {voucherOption === 1 ? 'percentage' : 'amount'}
                                            </span><br />
                                            <span className='label-sec-text'>
                                                The percentage or value you’d like to discount.
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            {voucherOption === 1 ?
                                                <TextField
                                                    margin="dense"
                                                    id="name"
                                                    placeholder="Discount Percentage"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={voucher?.discount_value}
                                                    {...register("discount_value", { required: true })}
                                                    error={errors.discount_value?.type}
                                                    helperText={errors.discount_value?.type === "required" && (
                                                        'Discount Percentage is required if voucher is percentage'
                                                    )}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{ shrink: false }}
                                                /> :
                                                <TextField
                                                    margin="dense"
                                                    id="name"
                                                    placeholder="Discount Amount"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={voucher?.discount_value}
                                                    {...register("discount_value", { required: true })}
                                                    error={errors.discount_value?.type}
                                                    helperText={errors.discount_value?.type === "required" && (
                                                        'Discount Amount is required if voucher is value'
                                                    )}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">{GLOBAL_CURRENCY.symbol}</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{ shrink: false }}
                                                />}
                                        </FormControl>
                                    </div>
                                </Grid>
                                {voucherOption === 1 ?
                                    <>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <div className='form-wrapper'>
                                                <FormLabel className='form-label'>
                                                    <span className='label-text'>
                                                        Maximum Amount
                                                    </span><br />
                                                    <span className='label-sec-text'>
                                                        Select maximum amount applicable.
                                                    </span>
                                                </FormLabel>
                                                <FormControl className='form-control'>
                                                    <TextField
                                                        margin="dense"
                                                        id="name"
                                                        placeholder="Maximum Amount"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        defaultValue={voucher?.maximum_discount}
                                                        {...register("maximum_discount")}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{GLOBAL_CURRENCY.symbol}</InputAdornment>,
                                                        }}
                                                        InputLabelProps={{ shrink: false }}
                                                    />
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}></Grid>
                                    </>
                                    : ''}
                                <Grid item md={6} sm={12} xs={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Select which customers it applies to
                                            </span><br />
                                            <span className='label-sec-text'>
                                                i.e apply this discount to new customers only.
                                            </span>
                                        </FormLabel>
                                        <FormControl fullWidth className='select-wrapper'>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue={voucher?.customer_type}
                                                {...register("customer_type", { required: true })}
                                                error={errors.customer_type?.type}
                                                fullWidth
                                                size="small"
                                            >
                                                <MenuItem value={'all'}>All Customers</MenuItem>
                                                <MenuItem value={'new'}>New Customers</MenuItem>
                                            </Select>
                                            {errors.customer_type &&
                                                <span className="error-text">
                                                    Type of customer for the voucher is required.
                                                </span>}
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Number of uses per customer
                                            </span><br />
                                            <span className='label-sec-text'>
                                                Select the number of times a customer can apply this discount.
                                            </span>
                                        </FormLabel>
                                        <FormControl fullWidth className='select-wrapper'>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue={voucher?.uses_per_customer}
                                                {...register("uses_per_customer", { required: true })}
                                                error={errors.uses_per_customer?.type}
                                                fullWidth
                                                size="small"
                                            >
                                                <MenuItem value={'1'}>Once</MenuItem>
                                                <MenuItem value={'100'}>Unlimited</MenuItem>
                                            </Select>
                                            {errors.uses_per_customer &&
                                                <span className="error-text">
                                                    Number of uses per customer is required.
                                                </span>}
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className='header-wrapper'>
                                <span className='header-text'>
                                    Optional
                                </span>
                            </div>
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                    Use the optional fields below to customise and set limits for your voucher.
                                </span>
                            </div>
                            <Grid container className='dashboard-form-container' spacing={4} wrap='wrap'>
                                <Grid item md={6} sm={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Apply to orders above a certain amount (optional)
                                            </span><br />
                                            <span className='label-sec-text'>
                                                Set a minimum spend if applicable.
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <TextField
                                                margin="dense"
                                                id="name"
                                                placeholder="Minimum Order Value"
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                defaultValue={voucher?.minimum_amount}
                                                {...register("minimum_amount")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{GLOBAL_CURRENCY.symbol}</InputAdornment>,
                                                }}
                                                InputLabelProps={{ shrink: false }}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={12}></Grid>
                                {/* <Grid item md={6} sm={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Start Date
                                            </span>
                                        </FormLabel>
                                        <FormControl fullWidth className='select-wrapper'>
                                            <Controller
                                                control={control}
                                                name="start_date"
                                                rules={{ required: true }}
                                                defaultValue={moment(voucher?.start_date)}
                                                render={({ field: { onChange, value } }) => (
                                                    <div className='date-wrapper'>
                                                        <ResponsiveDatePickers
                                                            value={value}
                                                            defaultValue={moment(voucher?.start_date)}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            {errors.start_date &&
                                                <span className="error-text">Start date is required.
                                                </span>}
                                        </FormControl>
                                    </div>
                                </Grid> */}
                                <Grid item md={6} sm={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Start Date
                                            </span>
                                        </FormLabel>
                                        <FormControl fullWidth className='select-wrapper'>
                                            <Controller
                                                control={control}
                                                name="start_date"
                                                rules={{ required: true }}
                                                defaultValue={voucher?.start_date ? moment(voucher?.start_date) : null}
                                                render={({ field: { onChange, value } }) => (
                                                    <div className='date-wrapper'>
                                                        <ResponsiveDatePickers
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            {errors.start_date &&
                                                <span className="error-text">Start date is required.</span>}
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                End Date
                                            </span>
                                        </FormLabel>
                                        <FormControl fullWidth className='select-wrapper'>
                                            <Controller
                                                control={control}
                                                name="end_date"
                                                rules={{ required: true }}
                                                defaultValue={voucher?.end_date ? moment(voucher?.end_date) : null}
                                                render={({ field: { onChange, value } }) => (
                                                    <div className='date-wrapper'>
                                                        <ResponsiveDatePickers
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            {errors.end_date &&
                                                <span className="error-text">End date is required.</span>}
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                                <Button variant="contained" color="success" onClick={() => discardNewVoucher(false)}>
                                    Discard
                                </Button>
                                <Button variant="contained" color="secondary" onClick={() => {
                                    sendOTP()
                                    // var submitForm = document.getElementById("submit_button")
                                    // console.log('Submit ', submitForm)
                                    // submitForm.click()
                                }}>
                                    Create Voucher
                                </Button>
                                <Button
                                    type="submit"
                                    style={{ visibility: 'hidden' }}
                                    id="submit_button">
                                    Submit
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                    <Dialog open={openOTPModal} className="form-dialog-wrapper">
                        <DialogTitle>
                            <span className="title-text">Verify OTP and create voucher</span>
                        </DialogTitle>
                        <Divider />
                        <DialogContent
                            sx={{
                                width: "400px",
                                minHeight: "200px",  /* Increased height to fit everything */
                                overflow: "hidden",   /* Prevents scroll */
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}
                        >
                            <div className="form-wrapper">
                                <FormLabel className="form-label">
                                    <span className="label-text">OTP</span><br />
                                    <span className="label-sec-text">
                                        Enter OTP sent to your registered email address.
                                    </span>
                                </FormLabel>
                                <FormControl className="form-control">
                                    <OTP
                                        style={{ marginTop: "1rem" }}
                                        separator={<span>-</span>}
                                        value={otp}
                                        onChange={setOTP}
                                        length={5}
                                    />
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: 2,
                                padding: "16px 24px",
                                marginBottom: "10px" /* Extra space for buttons */
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                sx={{ width: 120, height: 40 }}
                                onClick={() => showOTPModal(false)}
                            >
                                BACK
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                size="10px"
                                sx={{ width: 140, height: 40 }}  /* Adjusted width */
                                onClick={() => {
                                    var submitForm = document.getElementById("submit_button");
                                    submitForm.click();
                                }}
                            >
                                CREATE
                            </Button>
                        </DialogActions>
                    </Dialog>

                </form>
            </Grid>
        </Grid>
    )
}

export default NewVoucher
import { useState, useEffect } from 'react';

import {
    Card,
    Divider,
    Grid,
    CardContent,
    CardActions,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TablePagination,
    Box,
    Button,
    TextField,
    IconButton,
    Typography
} from '@mui/material';

import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import TableWithAction from 'components/Tables/TableWithAction';

import { orderStatus } from 'constants/appData/filters';

import SearchInput from 'components/Search';

import { ReactComponent as NoData } from 'assets/svgs/NoData.svg';
import CustomBreadcrumbs from "components/Breadcrumbs";
import OrderDetails from '../Orders/OrderDetails';
import { GLOBAL_CURRENCY } from 'constants/appConstants';
import moment from 'moment';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import styles
import { format, differenceInDays } from "date-fns"; // For formatting dates
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"; // Calendar Icon
import { enqueueSnackbar } from 'notistack';

let formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: GLOBAL_CURRENCY.code,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2
});

const SalesReport = ({ breadcrumbs }) => {
    const [loader, setLoader] = useState(false);
    const [allOrders, setOrderList] = useState([]);
    const [allOrdersDownload, setOrderListDownload] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [status, setOrderStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [orderId, selectOrder] = useState('');
    const [salesSummary, setSalesSummary] = useState({});
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const handleDateChange = (update) => {
        if (update[0] && update[1]) {
            const daysDifference = differenceInDays(update[1], update[0]);

            if (daysDifference > 30) {
                // alert("You can only select a maximum duration of 30 days.");
                enqueueSnackbar("You can only select a maximum duration of 30 days.", { variant: "error" });
                return;
            }
        }
        setDateRange(update);
    };


    const getOrderList = async () => {
        console.log('Start Date', startDate && format(startDate, "yyyy-MM-dd"),)
        // return
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_ORDERS, {
            params: {
                page: pageCount + 1,
                search: searchString,
                status: status,
                paginate: true,
                from_date: startDate && format(startDate, "yyyy-MM-dd"),
                to_date: endDate && format(endDate, "yyyy-MM-dd")
            },
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data) {
            setOrderList(data.results);
            setTotalCount(data.count);
            setSearchString("")
        }
        setLoader(false)
    }

    console.log('start dateeeee', startDate, endDate)
    // console.log('end dateeeee',endDate)
    const getOrderListDownload = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_ORDERS, {
            params: {
                page: pageCount + 1,
                search: searchString,
                status: status,
                paginate: false,
                from_date: format(startDate, "yyyy-MM-dd"),
                to_date: format(endDate, "yyyy-MM-dd")
            },
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data) {
            setOrderListDownload(data);
            setTotalCount(data.length);
            setSearchString("")
            console.log('start dateeeee console', startDate, endDate)
            setLoader(false);
            return data
        }
        setLoader(false)
    }

    const getSalesReport = async () => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.SALES_ANALYTICS)
        if (res === false) {
            setLoader(false);
            return
        }

        let { data, response } = res;

        if (data) {
            setSalesSummary(data)
            setLoader(false);
            return data
        }
        setLoader(false);
    }

    useEffect(() => {
        getSalesReport()
        getOrderList()
    }, [status, pageCount, dateRange])

    const handleChangePage = (e, page) => {
        setPageCount(page)
    }

    const handleEdit = (order) => {
        // selectOrder(order.id)
    }

    const downloadExcel = async () => {
        try {
            const allOrdersDownload = await getOrderListDownload();
            const salesSummary = await getSalesReport();
    
            // 1. Prepare Order Data and Remove Empty Fields, 'id' and modify order_id and transaction_id and format dates
            const modifiedOrders = allOrdersDownload.map(({
                order_id, transaction_id, product_name, order_instruction, customer_name, merchant_name, quantity, currency, unit_price, total_price, payment_status, payment_mode, pay_by, order_notes, delivery_status_type, shipping_address, billing_address, warehouse_address, created, updated, ...rest
            }) => {
                let modifiedOrderId = order_id;
                if (typeof order_id === 'string' && order_id.length > 6) {
                    modifiedOrderId = order_id.substring(6);
                }
    
                let modifiedTransactionId = transaction_id;
                if (typeof transaction_id === 'string' && transaction_id.length > 7) {
                    modifiedTransactionId = transaction_id.substring(7);
                }
    
                let createdDate = "";
                let createdTime = "";
                if (created) {
                    const createdDateTime = new Date(created);
                    createdDate = createdDateTime.toLocaleDateString();
                    createdTime = createdDateTime.toLocaleTimeString();
                }
    
                let updatedDate = "";
                let updatedTime = "";
                if (updated) {
                    const updatedDateTime = new Date(updated);
                    updatedDate = updatedDateTime.toLocaleDateString();
                    updatedTime = updatedDateTime.toLocaleTimeString();
                }
    
                const order = {
                    order_id: modifiedOrderId,
                    transaction_id: modifiedTransactionId,
                    product_name: product_name || "",
                    order_notes: order_instruction || "",
                    customer_name: customer_name || "",
                    merchant_name: merchant_name || "",
                    quantity: quantity || "",
                    currency: currency || "",
                    unit_price: unit_price || "",
                    total_price: total_price || "",
                    payment_status: payment_status || "",
                    payment_mode: payment_mode?.payment_method_name || "",
                    pay_at_store: pay_by || "",
                    payment_instructions: order_notes || "",
                    delivery_status_type: delivery_status_type || "",
                    shipping_address: shipping_address?.street_address || "",
                    billing_address: billing_address?.street_address || "",
                    warehouse_address: warehouse_address?.address_line_1 || "",
                    created_date: createdDate,
                    created_time: createdTime,
                    updated_date: updatedDate,
                    updated_time: updatedTime,
                };
    
                // Remove empty fields
                Object.keys(order).forEach(key => {
                    if (order[key] === "" || order[key] === null || order[key] === undefined) {
                        delete order[key];
                    }
                });
    
                return order;
            });
    
            // 2. Create Order Worksheet
            const orderWorksheet = XLSX.utils.json_to_sheet(modifiedOrders);
    
            // 3. Calculate Order Column Widths
            const orderColumnWidths = calculateColumnWidths(modifiedOrders);
            orderWorksheet['!cols'] = orderColumnWidths;
    
            // 4. Prepare Sales Summary Data
            const salesSummaryData = [
                { Metric: 'Total Sales', Value: salesSummary?.total_sale || 0 },
                { Metric: 'Today\'s Sales', Value: salesSummary?.today_sale || 0 },
            ];
    
            // 5. Create Sales Worksheet
            const salesWorksheet = XLSX.utils.json_to_sheet(salesSummaryData);
    
            // 6. Calculate Sales Column Widths (if needed)
            const salesColumnWidths = calculateColumnWidths(salesSummaryData);
            salesWorksheet['!cols'] = salesColumnWidths;
    
            // 7. Create Workbook and Download
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, orderWorksheet, "Orders");
            XLSX.utils.book_append_sheet(workbook, salesWorksheet, "Sales");
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
            const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(dataBlob, "orders_and_sales.xlsx");
        } catch (error) {
            console.error("Error downloading orders and sales report:", error);
            // Handle the error appropriately (e.g., show a user-friendly message)
        }
    };
    


    // Function to Calculate Column Widths
    function calculateColumnWidths(data) {
        const maxWidths = {};

        // Get Header Row (Column Names)
        const headerRow = data[0];

        // Iterate over HEADER Row and find the maximum length for each column
        Object.keys(headerRow).forEach(key => {
            const cellValue = key ? key.toString() : "";
            maxWidths[key] = cellValue.length; // Initialize with header length
        });

        // Iterate over the DATA rows and find the maximum length for each column
        data.forEach(row => {
            Object.keys(row).forEach(key => {
                const cellValue = row[key] ? row[key].toString() : "";
                maxWidths[key] = Math.max(maxWidths[key] || 0, cellValue.length);
            });
        });

        // Convert to Excel-friendly widths (wch)
        return Object.keys(maxWidths).map(key => ({
            wch: Math.max(10, maxWidths[key] * 1.2), // Minimum width of 10
        }));
    }

    return (
        <div className="store-analytics-wrapper website-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Sales Report"} />
            </div>
            {orderId ?
                <OrderDetails orderId={orderId} discardUpdateOrder={() => {
                    selectOrder()
                    getOrderList()
                }} />
                : <Grid container className='component-grid'>
                    <Grid item xs={12}>
                        <Card className='list-wrapper dashboard-card-wrapper'>
                            <CardContent>
                                <div className='header-wrapper'>
                                    <span className='header-text'>Reports</span>
                                </div>
                                <Divider />
                                <div className='content-wrapper content-flex'>
                                    <span className='content-text'>
                                        {/* You can manage your customer orders here. Below you will see a list of your current orders based on their status, you can see whether an order is placed, whether it has been paid for and whether or not you have shipped the order to the customer. You can manage each order by clicking on the “Manage” order button. You can filter orders using the filter control, alternatively you can also search for orders by customer name or email address. */}
                                        Download excel sheet on the basis of date range
                                    </span>
                                </div>
                                {/* <Grid item md={4} sm={1} xs={12} /> */}
                                <Grid
                                    item
                                    md={6}
                                    sm={8}
                                    xs={12}
                                    display="flex"
                                    alignItems="center" // Align items in center
                                    // justifyContent="center" // Center the grid
                                    gap={1.5} // Reduce gap slightly
                                    // sx={{ margin: "0 auto", textAlign: "center" }} // Center the grid horizontally
                                    style={{ marginTop: "20px", marginBottom: "20px" }}
                                >
                                    {/* Date Picker with Calendar Icon */}
                                    {/* <Box position="relative" sx={{ height: "40px", display: "flex", alignItems: "center" }}> Reduced height */}
                                    {/* <DatePicker
                                            selectsRange
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={handleDateChange}
                                            isClearable
                                            dateFormat="dd/MM/yy"
                                            placeholderText="Select date range"
                                            customInput={
                                                <TextField
                                                    label="Date Range"
                                                    size="small"
                                                    fullWidth
                                                    sx={{
                                                        height: "36px",
                                                        "& .MuiInputBase-root": { height: "36px" }, // Ensures input field height matches
                                                        "& .MuiInputLabel-root": { top: "-5px" }, // Adjust label positioning
                                                    }}
                                                    InputProps={{
                                                        readOnly: true, // Prevents user typing in input
                                                        endAdornment: (
                                                            <IconButton sx={{ padding: "5px" }}>
                                                                <CalendarTodayIcon fontSize="small" />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                />
                                            }

                                        /> */}

                                    {/* </Box> */}
                                    {/* Date Picker with Calendar Icon */}
                                    <Box position="relative" sx={{ height: "38px" }}> {/* Reduced height */}
                                        <DatePicker
                                            selectsRange
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={handleDateChange}
                                            isClearable
                                            dateFormat="dd/MM/yy"
                                            placeholderText="Select date range"
                                            popperClassName="date-picker-popper"
                                            customInput={
                                                <TextField
                                                    label="Date Range"
                                                    size="small"
                                                    fullWidth
                                                    sx={{
                                                        height: "38px",
                                                        "& .MuiInputBase-root": { height: "40px", color: "black" }, // Set text color to gray
                                                        "& .MuiInputLabel-root": { top: "-5px", color: "gray" }, // Set label color to gray
                                                    }}
                                                    InputProps={{
                                                        readOnly: true, // Prevents user typing in input
                                                        endAdornment: (
                                                            <IconButton sx={{ marginRight: startDate ? "10px" : "" }}>
                                                                <CalendarTodayIcon fontSize="small" />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    style={{ fontSize: "0.6rem" }}
                                                />
                                            }
                                        />

                                        {/* Display selected dates below */}
                                        {startDate && endDate && (
                                            <Typography variant="body2" sx={{ mt: 0.5, color: "gray", textAlign: "center" }}>
                                                Date Format: dd/mm/yy
                                            </Typography>
                                        )}

                                    </Box>

                                    {/* CSS to fix overlapping issue */}
                                    <style>
                                        {`
                                            .date-picker-popper {
                                                z-index: 1500 !important;
                                            }
                                        `}
                                    </style>

                                    {/* Download Button - Adjusted height */}
                                    <Button
                                        sx={{
                                            width: { xs: "38vw", sm: "9vw", md: "12vw" },
                                            height: "40px", // Same height as Date Picker
                                            backgroundColor: "black",
                                            color: "white",
                                            fontSize: "12px",
                                            padding: "5px 10px", // Adjust padding for smaller size
                                            "&:hover": {
                                                backgroundColor: "#333",
                                            },
                                        }}
                                        disabled={startDate === null || endDate === null}
                                        onClick={downloadExcel}
                                        variant="contained"
                                    >
                                        📄 <span style={{ fontSize: "14px", marginLeft: "3px" }}> Download Excel </span>
                                    </Button>
                                </Grid>

                                <div className='content-wrapper content-flex'  >
                                    <Grid container className='filter-wrapper' >
                                        <Grid item md={2} sm={2} xs={4}>
                                            <span className='detail-sec-text'>
                                                Filter by:
                                            </span>
                                        </Grid>
                                        <Grid item md={2} sm={3} xs={8} className='select-wrapper'>
                                            <FormControl fullWidth>
                                                <InputLabel sx={{ fontSize: '16px', color: "gray", }} size="small" id="demo-simple-select-label">Order Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={status}
                                                    size="small"
                                                    label="Order Status"
                                                    onChange={e => {
                                                        setOrderStatus(e.target.value)
                                                    }}
                                                    MenuProps={{
                                                        sx: {
                                                            "& .MuiMenuItem-root": {
                                                                fontSize: '16px' // Change this value to your desired font size
                                                            },
                                                            "& .MuiList-root": {
                                                                fontSize: '16px' // This affects the list item font size
                                                            }
                                                        }
                                                    }}
                                                    sx={{
                                                        fontSize: '16px' // Change this value to your desired font size
                                                    }}
                                                >
                                                    <MenuItem value={'All'}>All</MenuItem>
                                                    {orderStatus.length > 0 ? orderStatus.map((status) => (
                                                        <MenuItem key={status.value} value={status.value}>{status.label}</MenuItem>
                                                    )) : ''}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item sm={2} className='select-wrapper'>
                                    <FormControl fullWidth>
                                        <InputLabel size="small" id="demo-simple-select-label">Payment Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={paymentStatus}
                                            label="Payment Status"
                                            size="small"
                                            onChange={e => {
                                                setPaymentStatus(e.target.value)
                                                getOrderList()
                                            }}
                                        >
                                            <MenuItem value={''}>All</MenuItem>
                                            <MenuItem value={'processing'}>Processing</MenuItem>
                                            <MenuItem value={'complete'}>Complete</MenuItem>
                                            <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid> */}

                                        {/* <Grid item md={4} sm={1} xs={12} /> */}
                                        <Grid item md={4} sm={6} xs={12} className='content-flex content-flex-right'>
                                            <SearchInput
                                                value={searchString}
                                                onChange={(e) => {
                                                    setSearchString(e.target.value)

                                                }}
                                                style={{ fontSize: '14px' }}
                                                onEnter={getOrderList}
                                            // onBlur={getOrderList}
                                            />
                                        </Grid>
                                    </Grid>


                                </div>



                                {allOrders.length === 0 ?
                                    <div className='content-wrapper content-flex content-flex-center'>
                                        <NoData />
                                        <div className='detail-action-wrapper content-flex content-flex-center'>
                                            <span className='detail-sec-text'>
                                                No order details yet
                                            </span>
                                        </div>
                                    </div> :
                                    <Grid container className='dashboard-table-container' spacing={4} wrap='wrap'>
                                        <Grid item md={12} sm={12}>
                                            <TableWithAction
                                                domain={"salesReport"}
                                                rows={allOrders}
                                                headers={[{
                                                    label: 'Order Id',
                                                    name: 'order_id',
                                                    width: '10%'
                                                }, {
                                                    label: 'Transaction Id',
                                                    name: 'transaction_id',
                                                    width: '10%'
                                                }, {
                                                    label: 'Product Name',
                                                    name: 'product_name',
                                                    width: '20%'
                                                }, {
                                                    label: 'Quantity',
                                                    name: 'quantity',
                                                }, {
                                                    label: 'Unit Price',
                                                    type: 'custom',
                                                    arguments: ['unit_price'],
                                                    component: (unitPrice) => {
                                                        return (
                                                            <div className='rating-wrapper'>
                                                                <span>{formatCurrency.format(parseInt(unitPrice) || 0)}</span>
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'Total Price',
                                                    type: 'custom',
                                                    arguments: ['total_price'],
                                                    component: (totalPrice) => {
                                                        return (
                                                            <div className='rating-wrapper'>
                                                                <span>{formatCurrency.format(parseInt(totalPrice) || 0)}</span>
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'Created On',
                                                    type: 'custom',
                                                    arguments: ['created'],
                                                    component: (created) => {
                                                        return (
                                                            <div className='rating-wrapper'>
                                                                <span>{moment(created).format("[On] ddd[,] D MMM YYYY")}</span>
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'Order Status',
                                                    name: 'delivery_status_type',
                                                    // type: 'custom',
                                                    // arguments: ['delivery_status_type'],
                                                    // component: (type) => {
                                                    //     return (
                                                    //         <div className='cell-label'>
                                                    //             <span>{type}</span>
                                                    //             {/* <CheckCircleIcon /> <span>{count > 0 ? "Available" : "Out of Stock"}</span> */}
                                                    //         </div>
                                                    //     )
                                                    // }
                                                }]}
                                                handleEdit={handleEdit}
                                            />
                                            <TablePagination
                                                rowsPerPageOptions={[]}
                                                component="div"
                                                count={totalCount}
                                                rowsPerPage={totalCount < 10 ? totalCount : 10}
                                                page={pageCount}
                                                onPageChange={handleChangePage}
                                            />
                                        </Grid>
                                    </Grid>}
                            </CardContent>
                            <CardActions className='footer-wrapper'>
                                <div className="action-wrapper">
                                    {/* <Button variant="contained" color="error" onClick={() => discardNewProduct(false)}>
                                Discard
                            </Button>
                            <Button variant="contained" color="secondary">
                                Save Product
                            </Button> */}
                                </div>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>}
        </div>
    )
}

// const StyledButton = styled(Button)(({ theme }) => ({
//     color: theme.palette.getContrastText('black'), // White text (dynamic contrast)
//     backgroundColor: 'black',
//     '&:hover': {
//         backgroundColor: '#333', // Darker black on hover
//     },
//     paddingLeft: '16px', // Adjust padding for icon placement
//     paddingRight: '16px', // Adjust padding for icon placement

// }));

export default SalesReport
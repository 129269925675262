import { useState, useEffect } from 'react';

import {
    Card,
    Divider,
    Grid,
    Button,
    CardContent,
    CardActions,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import AddCircle from '@mui/icons-material/AddCircleOutline';

import SearchInput from 'components/Search';
import EditIcon from '@mui/icons-material/Edit';

import { ReactComponent as NoData } from 'assets/svgs/NoData.svg';
import CustomBreadcrumbs from "components/Breadcrumbs";
import NewVoucher from './NewVoucher';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import TableWithAction from 'components/Tables/TableWithAction';

const Vouchers = ({ breadcrumbs }) => {
    const [loader, setLoader] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const [isNewVoucher, setNewVoucher] = useState(false);
    const [isEditVoucher, setEditVoucher] = useState(false);
    const [selectedVoucher, setSelectedVoucher] = useState('');
    const [allVouchers, setVoucherList] = useState([]);
    const [status, setStatus] = useState('');
    const [searchString, setSearchString] = useState('');
    const [width, setWidth] = useState(getWidth());

    function getWidth() {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1200) return 120;
        if (screenWidth >= 992) return 140;
        if (screenWidth >= 768) return 120;
        if (screenWidth >= 576) return 100;
        return 100; // Default for extra small screens
    }

    useEffect(() => {
        const handleResize = () => setWidth(getWidth());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDiscardVoucher = () => {
        if (isNewVoucher) setNewVoucher(false)
        else setEditVoucher(false);
    }

    const handleEditVoucher = (voucher) => {
        setSelectedVoucher(voucher);
        setEditVoucher(true);
    }

    const getVoucherList = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_VOUCHERS, {
            params: {
                page: pageCount + 1,
                // search: searchString
            }
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data && data.results) {
            setVoucherList(data.results)
            setTotalCount(data.count);
            setPageCount(data.current_page - 1);
        }
        setLoader(false)


        // let queryParams = {
        //     page: pageCount + 1,
        // }
        // if (searchString) queryParams['search'] = searchString
        // if (status) queryParams['status'] = status
        // let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_VOUCHERS, {
        //     params: queryParams
        // })
        // if (res === false) {
        //     setLoader(false);
        //     return
        // }
        // let { data, response } = res;

        // if (data && data.results) {
        //     setVoucherList(data.results)
        //     setTotalCount(data.count);
        //     setPageCount(data.current_page - 1);
        // }
        // setLoader(false)
    }

    useEffect(() => {
        getVoucherList()
    }, [pageCount, status])

    // const handleChangePage = () => {
    //     setPageCount(pageCount + 1)
    // }
    const handleChangePage = (e, page) => {
        setPageCount(page);
    }

    return (
        <div className="store-analytics-wrapper website-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Vouchers"} />
            </div>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    {isNewVoucher || isEditVoucher ?
                        <NewVoucher
                            isNew={isNewVoucher}
                            voucher={selectedVoucher}
                            discardNewVoucher={() => {
                                handleDiscardVoucher(false)
                                getVoucherList()
                            }} /> :
                        <Card className='list-wrapper dashboard-card-wrapper'>
                            <CardContent>
                                <div className='header-wrapper header-flex'>
                                    <span className='header-text'>Voucher Details</span>
                                    <Button
                                        onClick={() => setNewVoucher(true)}
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        startIcon={<AddCircle />}>
                                        Create a New Voucher
                                    </Button>
                                </div>
                                <Divider />
                                <div className='content-wrapper content-flex'>
                                    <span className='content-text'>
                                        Create and edit discount vouchers for new and existing customers. You can then use these voucher codes for your marketing promotions on your social media channels or send them to your customers directly to use during checkout.
                                    </span>
                                </div>
                                <div className='content-wrapper content-flex'>
                                    <Grid container className='filter-wrapper'>
                                        <Grid item md={2} sm={2} xs={4}>
                                            <span className='detail-sec-text'>
                                                Filter by:
                                            </span>
                                        </Grid>
                                        <Grid item md={2} sm={3} xs={8} className='select-wrapper'>
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id="demo-simple-select-label">Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={status}
                                                    label="Status"
                                                    size="small"
                                                    onChange={e => setStatus(e.target.value)}
                                                >
                                                    <MenuItem value={''}>All</MenuItem>
                                                    <MenuItem value={'inactive'}>Inactive</MenuItem>
                                                    <MenuItem value={'active'}>Active</MenuItem>
                                                    <MenuItem value={'expired'}>Expired</MenuItem>
                                                    {/* <MenuItem value={'cancelled'}>Cancelled</MenuItem> */}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4} sm={1} xs={12} />
                                        {/* <Grid item sm={2} /> */}
                                        <Grid item md={4} sm={6} xs={12} className='content-flex content-flex-right'>
                                            <SearchInput
                                                value={searchString}
                                                onChange={e => setSearchString(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                {allVouchers.length ? (
                                    <Grid container className='dashboard-table-container' spacing={4} wrap='wrap'>
                                        <Grid item md={12} sm={12}>
                                            <TableWithAction
                                                rows={allVouchers}
                                                headers={[{
                                                    label: 'Voucher Name',
                                                    name: 'name',
                                                    width: '15%'
                                                }, {
                                                    label: 'Voucher Code',
                                                    name: 'code'
                                                }, {
                                                    label: 'Value',
                                                    type: 'custom',
                                                    width: '20%',
                                                    arguments: ['discount_value', 'voucher_type', 'maximum_discount', 'minimum_amount'],
                                                    component: (value, type, maxValue, minAmount) => {
                                                        return (
                                                            <div className='cell-text'>
                                                                <span>
                                                                    {type == 1 ?
                                                                        `${value}% upto ${maxValue} on minimum order of ${minAmount}` :
                                                                        `R${value} on minimum order of ${minAmount}`}
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'Expiry',
                                                    type: 'custom',
                                                    width: '10%',
                                                    arguments: ['end_date'],
                                                    component: (endDate) => {
                                                        return (
                                                            <div className='cell-label' style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                backgroundColor: 'success',
                                                                width: `${width}px`
                                                            }}>
                                                                <span>{endDate ? endDate : 'No Expiry'}</span>
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'Reedemed',
                                                    name: 'reedemed'
                                                }]}
                                                handleEdit={handleEditVoucher}
                                            />
                                            <TablePagination
                                                rowsPerPageOptions={[]}
                                                component="div"
                                                count={totalCount}
                                                rowsPerPage={totalCount < 10 ? totalCount : 10}
                                                page={pageCount}
                                                onPageChange={handleChangePage}
                                            />
                                        </Grid>
                                    </Grid>) : (
                                    <div className='content-wrapper content-flex content-flex-center'>
                                        <NoData />
                                        <div className='detail-action-wrapper content-flex content-flex-center'>
                                            <span className='detail-sec-text'>
                                                No voucher details yet
                                            </span>
                                        </div>
                                    </div>)}
                            </CardContent>
                            <CardActions className='footer-wrapper'>
                                <div className="action-wrapper" />
                            </CardActions>
                        </Card>}
                </Grid>
            </Grid>
        </div>
    )
}

export default Vouchers
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Box, Button, AppBar as SecondaryBar } from "@mui/material";
import ArticleIcon from '@mui/icons-material/Article';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";

import { SideNavigationLeft as SideNavigation } from "../../SideNavigation";
import { TransparentAppBar as AppBar } from "containers/AppBar";
import { useSnackbar } from "notistack";

import { APP_NAME, APP_LOGO } from "constants/appConstants";
import {
  APIRouteConstants,
  authRouteConstants,
  dashboardRouteConstants,
} from "constants/routeConstants";
import CampaignIcon from '@mui/icons-material/Campaign';
import HomeIcon from "@mui/icons-material/Home";
import WebsiteIcon from "@mui/icons-material/Language";
import StoreIcon from "@mui/icons-material/LocalGroceryStore";
import AccountIcon from "@mui/icons-material/AccountBalance";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoIcon from '@mui/icons-material/Info';
import BottomAppBar from "components/Navigation/BottomNavigation";
import { useSelector } from "react-redux";
import { updateUserDetail } from "utils/auth";
import Loader from "components/Loader";
import axiosInstance from "configs/axiosConfig";
import VerifiyOtp from "components/VerifiyOtp";

const theme = extendTheme({
  palette: {
    primary: {
      // main: "#01082c",
      main: "#00121a",
      // main: "#012231"
    },
    secondary: {
      main: "#1876d2",
    },
    custom: {
      main: "#babbbd",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const drawerWidth = 300;

const sideMenuItems = [
  {
    title: "Dashboard",
    path: dashboardRouteConstants.HOME,
    icon: <HomeIcon />,
  },
  {
    title: "Website",
    icon: <WebsiteIcon />,
    child: [
      //     {
      //     title: 'Site analytics',
      //     path: dashboardRouteConstants.WEB_ANALYTICS,
      // },
      {
        title: "Website templates",
        path: dashboardRouteConstants.WEB_TEMPLATE,
      },
      {
        title: "Domain(s)",
        path: dashboardRouteConstants.DOMAIN,
      },
      {
        title: "Contact details",
        path: dashboardRouteConstants.CONTACT,
      },
      {
        title: "About",
        path: dashboardRouteConstants.ABOUT,
      },
      {
        title: "Terms & Conditions",
        path: dashboardRouteConstants.WEB_TERMS_AND_CONDITION,
      },
      {
        title: "Returns & Refunds",
        path: dashboardRouteConstants.PRIVACY_POLICY,
      },
      // {
      //   title: "Refund Policy",
      //   path: dashboardRouteConstants.REFUND_POLICY,
      // },
    ],
  },
  {
    title: "Store",
    icon: <StoreIcon />,
    child: [
      {
        title: "Update Store",
        path: dashboardRouteConstants.UpdateStore,
        // icon: <HomeIcon />,
      },
      {
        //     title: 'Store analytics',
        //     path: dashboardRouteConstants.STORE_ANALYTICS
        // },{
        title: "Categories",
        path: dashboardRouteConstants.CATEGORIES,
      },
      {
        title: "Products",
        path: dashboardRouteConstants.PRODUCTS,
      },
      // {
      //   title: "Customers",
      //   path: dashboardRouteConstants.CUSTOMERS,
      // },
      // {
      //   title: "Orders",
      //   path: dashboardRouteConstants.ORDERS,
      // },
      // {
      //   title: "Vouchers",
      //   path: dashboardRouteConstants.VOUCHERS,
      // },
      {
        title: "Payments",
        path: dashboardRouteConstants.PAYMENTS,
      },
      {
        title: "Delivery options",
        path: dashboardRouteConstants.DELIVERY,
        // },{
        //     title: 'Reviews',
        //     path: dashboardRouteConstants.REVIEWS
      },
      {
        title: "Shipping option",
        path: dashboardRouteConstants.SHIPPING,
      },
    ],
  },

  {
    title: "Marketing",
    // path: dashboardRouteConstants.HOME,
    icon: <CampaignIcon />,
    child: [
      {
        title: "Vouchers",
        path: dashboardRouteConstants.VOUCHERS,
      },
    ]
  },

  {
    title: "Reports",
    // path: dashboardRouteConstants.HOME,
    icon: <ArticleIcon />,
    child: [
      {
        title: "Orders",
        path: dashboardRouteConstants.ORDERS,
      },
      {
        title: "Sales Report",
        path: dashboardRouteConstants.SALESREPORT,
      },
      {
        title: "Customers",
        path: dashboardRouteConstants.CUSTOMERS,
      },
    ]
  },

  {
    title: "Account",
    icon: <AccountIcon />,
    child: [
      {
        title: "Account details",
        path: dashboardRouteConstants.ACC_DETAIL,
      },
      {
        title: "Billing details",
        path: dashboardRouteConstants.BILLING_DETAIL,
      },
      // {
      //     title: 'My stores',
      //     path: dashboardRouteConstants.MY_STORE
      // }
    ],
  },

  {
    title: 'Required Checklist',
    icon: <InfoIcon />,
    path: "info"
  },

  // {
  //   title: 'Shipping option',
  //   icon: <InfoIcon />,
  //   path: dashboardRouteConstants.SHIPPING
  // },
  {
    title: "Logout",
    icon: <LogoutIcon />,
    path: authRouteConstants.LOGOUT,
  },
];
const sideMenuItemsBottom = [
  {
    title: "Dashboard",
    path: dashboardRouteConstants.HOME,
    icon: <HomeIcon />,
  },
  // {
  //   title: "Update Store",
  //   path: dashboardRouteConstants.UpdateStore,
  //   icon: <HomeIcon />,
  // },
  {
    title: "Website",
    icon: <WebsiteIcon />,
    child: [
      //     {
      //     title: 'Site analytics',
      //     path: dashboardRouteConstants.WEB_ANALYTICS,
      // },
      {
        title: "Website templates",
        path: dashboardRouteConstants.WEB_TEMPLATE,
      },
      {
        title: "Domain(s)",
        path: dashboardRouteConstants.DOMAIN,
      },
      {
        title: "Contact details",
        path: dashboardRouteConstants.CONTACT,
      },
      {
        title: "About",
        path: dashboardRouteConstants.ABOUT,
      },
      {
        title: "Terms & Conditions",
        path: dashboardRouteConstants.WEB_TERMS_AND_CONDITION,
      },
      {
        title: "Returns & Refunds",
        path: dashboardRouteConstants.PRIVACY_POLICY,
      },
      // {
      //   title: "Refund Policy",
      //   path: dashboardRouteConstants.REFUND_POLICY,
      // },
    ],
  },
  {
    title: "Store",
    icon: <StoreIcon />,
    child: [
      {
        title: "Update Store",
        path: dashboardRouteConstants.UpdateStore,
        // icon: <HomeIcon />,
      },
      {
        //     title: 'Store analytics',
        //     path: dashboardRouteConstants.STORE_ANALYTICS
        // },{
        title: "Categories",
        path: dashboardRouteConstants.CATEGORIES,
      },
      {
        title: "Products",
        path: dashboardRouteConstants.PRODUCTS,
      },
      // {
      //   title: "Customers",
      //   path: dashboardRouteConstants.CUSTOMERS,
      // },
      // {
      //   title: "Orders",
      //   path: dashboardRouteConstants.ORDERS,
      // },
      // {
      //   title: "Vouchers",
      //   path: dashboardRouteConstants.VOUCHERS,
      // },
      {
        title: "Payments",
        path: dashboardRouteConstants.PAYMENTS,
      },
      {
        title: "Delivery options",
        path: dashboardRouteConstants.DELIVERY,
        // },{
        //     title: 'Reviews',
        //     path: dashboardRouteConstants.REVIEWS
      },
      {
        title: "Shipping option",
        path: dashboardRouteConstants.SHIPPING,
      },
    ],
  },

  // {
  //   title: "Marketing",
  //   // path: dashboardRouteConstants.HOME,
  //   icon: <CampaignIcon />,
  //   child:[
  //     {
  //       title: "Vouchers",
  //       path: dashboardRouteConstants.VOUCHERS,
  //     },
  //   ]
  // },

  // {
  //   title: "Reports",
  //   // path: dashboardRouteConstants.HOME,
  //   icon: <CampaignIcon />,
  //   child:[
  //     {
  //       title: "Orders",
  //       path: dashboardRouteConstants.ORDERS,
  //     },
  //     {
  //       title: "Customers",
  //       path: dashboardRouteConstants.CUSTOMERS,
  //     },
  //   ]
  // },

  {
    title: "Account",
    icon: <AccountIcon />,
    child: [
      {
        title: "Account details",
        path: dashboardRouteConstants.ACC_DETAIL,
      },
      {
        title: "Billing details",
        path: dashboardRouteConstants.BILLING_DETAIL,
      },
      // {
      //     title: 'My stores',
      //     path: dashboardRouteConstants.MY_STORE
      // }
    ],
  },

  // {
  //   title: 'Required Checklist',
  //   icon: <InfoIcon />,
  //   path: "info"
  // },
  {
    title: "Logout",
    icon: <LogoutIcon />,
    path: authRouteConstants.LOGOUT,
  },
];
const sideMenuItemsforclient = [
  {
    title: "Dashboard",
    path: dashboardRouteConstants.HOME,
    icon: <HomeIcon />,
  },
  // {
  //   title: "Update Store",
  //   path: dashboardRouteConstants.UpdateStore,
  //   icon: <HomeIcon />,
  // },
  {
    title: "Website",
    icon: <WebsiteIcon />,
    child: [
      //     {
      //     title: 'Site analytics',
      //     path: dashboardRouteConstants.WEB_ANALYTICS,
      // },
      {
        title: "Website Customization",
        path: dashboardRouteConstants.CUSTOM_WEB_TEMPLATE,
      },
      {
        title: "Website templates",
        path: dashboardRouteConstants.WEB_TEMPLATE,
      },
      {
        title: "Domain(s)",
        path: dashboardRouteConstants.DOMAIN,
      },
      {
        title: "Contact details",
        path: dashboardRouteConstants.CONTACT,
      },
      {
        title: "About",
        path: dashboardRouteConstants.ABOUT,
      },
      {
        title: "Terms & Conditions",
        path: dashboardRouteConstants.WEB_TERMS_AND_CONDITION,
      },
      {
        title: "Returns & Refunds",
        path: dashboardRouteConstants.PRIVACY_POLICY,
      },
      // {
      //   title: "Refund Policy",
      //   path: dashboardRouteConstants.REFUND_POLICY,
      // },
    ],
  },
  {
    title: "Store",
    icon: <StoreIcon />,
    child: [
      {
        title: "Update Store",
        path: dashboardRouteConstants.UpdateStore,
        // icon: <HomeIcon />,
      },
      {
        //     title: 'Store analytics',
        //     path: dashboardRouteConstants.STORE_ANALYTICS
        // },{
        title: "Categories",
        path: dashboardRouteConstants.CATEGORIES,
      },
      {
        title: "Products",
        path: dashboardRouteConstants.PRODUCTS,
      },
      // {
      //   title: "Customers",
      //   path: dashboardRouteConstants.CUSTOMERS,
      // },
      // {
      //   title: "Orders",
      //   path: dashboardRouteConstants.ORDERS,
      // },
      // {
      //   title: "Vouchers",
      //   path: dashboardRouteConstants.VOUCHERS,
      // },
      {
        title: "Payments",
        path: dashboardRouteConstants.PAYMENTS,
      },
      {
        title: "Delivery options",
        path: dashboardRouteConstants.DELIVERY,
        // },{
        //     title: 'Reviews',
        //     path: dashboardRouteConstants.REVIEWS
      },
      {
        title: "Shipping option",
        path: dashboardRouteConstants.SHIPPING,
      },
    ],
  },
  {
    title: "Marketing",
    // path: dashboardRouteConstants.HOME,
    icon: <CampaignIcon />,
    child: [
      {
        title: "Vouchers",
        path: dashboardRouteConstants.VOUCHERS,
      },
    ]
  },

  {
    title: "Reports",
    // path: dashboardRouteConstants.HOME,
    icon: <ArticleIcon />,
    child: [
      {
        title: "Orders",
        path: dashboardRouteConstants.ORDERS,
      },
      {
        title: "Sales Report",
        path: dashboardRouteConstants.SALESREPORT,
      },
      {
        title: "Customers",
        path: dashboardRouteConstants.CUSTOMERS,
      },
    ]
  },

  {
    title: "Account",
    icon: <AccountIcon />,
    child: [
      {
        title: "Account details",
        path: dashboardRouteConstants.ACC_DETAIL,
      },
      {
        title: "Billing details",
        path: dashboardRouteConstants.BILLING_DETAIL,
      },
      // {
      //     title: 'My stores',
      //     path: dashboardRouteConstants.MY_STORE
      // }
    ],
  },
  {
    title: 'Required Checklist',
    icon: <InfoIcon />,
    path: "info"
  },

  {
    title: "Logout",
    icon: <LogoutIcon />,
    path: authRouteConstants.LOGOUT,
  },
];
const sideMenuItemsforclientBottom = [
  {
    title: "Dashboard",
    path: dashboardRouteConstants.HOME,
    icon: <HomeIcon />,
  },
  // {
  //   title: "Update Store",
  //   path: dashboardRouteConstants.UpdateStore,
  //   icon: <HomeIcon />,
  // },
  {
    title: "Website",
    icon: <WebsiteIcon />,
    child: [
      //     {
      //     title: 'Site analytics',
      //     path: dashboardRouteConstants.WEB_ANALYTICS,
      // },
      {
        title: "Website Customization",
        path: dashboardRouteConstants.CUSTOM_WEB_TEMPLATE,
      },
      {
        title: "Website templates",
        path: dashboardRouteConstants.WEB_TEMPLATE,
      },
      {
        title: "Domain(s)",
        path: dashboardRouteConstants.DOMAIN,
      },
      {
        title: "Contact details",
        path: dashboardRouteConstants.CONTACT,
      },
      {
        title: "About",
        path: dashboardRouteConstants.ABOUT,
      },
      {
        title: "Terms & Conditions",
        path: dashboardRouteConstants.WEB_TERMS_AND_CONDITION,
      },
      {
        title: "Returns & Refunds",
        path: dashboardRouteConstants.PRIVACY_POLICY,
      },
      // {
      //   title: "Refund Policy",
      //   path: dashboardRouteConstants.REFUND_POLICY,
      // },
    ],
  },
  {
    title: "Store",
    icon: <StoreIcon />,
    child: [
      {
        title: "Update Store",
        path: dashboardRouteConstants.UpdateStore,
        // icon: <HomeIcon />,
      },
      {
        //     title: 'Store analytics',
        //     path: dashboardRouteConstants.STORE_ANALYTICS
        // },{
        title: "Categories",
        path: dashboardRouteConstants.CATEGORIES,
      },
      {
        title: "Products",
        path: dashboardRouteConstants.PRODUCTS,
      },
      // {
      //   title: "Customers",
      //   path: dashboardRouteConstants.CUSTOMERS,
      // },
      // {
      //   title: "Orders",
      //   path: dashboardRouteConstants.ORDERS,
      // },
      // {
      //   title: "Vouchers",
      //   path: dashboardRouteConstants.VOUCHERS,
      // },
      {
        title: "Payments",
        path: dashboardRouteConstants.PAYMENTS,
      },
      {
        title: "Delivery options",
        path: dashboardRouteConstants.DELIVERY,
        // },{
        //     title: 'Reviews',
        //     path: dashboardRouteConstants.REVIEWS
      },
      {
        title: "Shipping option",
        path: dashboardRouteConstants.SHIPPING,
      },
    ],
  },
  // {
  //   title: "Marketing",
  //   // path: dashboardRouteConstants.HOME,
  //   icon: <CampaignIcon />,
  //   child:[
  //     {
  //       title: "Vouchers",
  //       path: dashboardRouteConstants.VOUCHERS,
  //     },
  //   ]
  // },

  // {
  //   title: "Reports",
  //   // path: dashboardRouteConstants.HOME,
  //   icon: <ArticleIcon />,
  //   child:[
  //     {
  //       title: "Orders",
  //       path: dashboardRouteConstants.ORDERS,
  //     },
  //     {
  //       title: "Customers",
  //       path: dashboardRouteConstants.CUSTOMERS,
  //     },
  //   ]
  // },

  {
    title: "Account",
    icon: <AccountIcon />,
    child: [
      {
        title: "Account details",
        path: dashboardRouteConstants.ACC_DETAIL,
      },
      {
        title: "Billing details",
        path: dashboardRouteConstants.BILLING_DETAIL,
      },
      // {
      //     title: 'My stores',
      //     path: dashboardRouteConstants.MY_STORE
      // }
    ],
  },
  // {
  //   title: 'Required Checklist',
  //   icon: <InfoIcon />,
  //   path: "info"
  // },
  {
    title: "Logout",
    icon: <LogoutIcon />,
    path: authRouteConstants.LOGOUT,
  },
];

const DashboardLayout = (props) => {
  const [loader, setLoader] = useState(false);
  const { appTitle } = props;
  const loggedInUser = useSelector((state) => state.userDetails);
  const { user } = loggedInUser;
  const [setupProgress, setSetupProgress] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  console.log("user");
  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (user.isPublished) {
        setSetupProgress(100);
      } else {
        let total = 6;
        let progress = 0;
        if (user.storeName && user.hostName) progress++;
        if (user.is_shiprazor_selected || user?.own_shipping_selected)
          progress++;
        if (user.warehouse_exist) progress++;
        if (user.is_category) progress++;
        if (user.is_product) progress++;
        if (user.is_payment_selected) progress++;
        setSetupProgress(Math.floor((progress / total) * 100));
      }
    }
  }, [user]);

  const getUser = async () => {
    let res = await axiosInstance.get(APIRouteConstants.AUTH.ME);
    if (res === false) {
      setLoader(false);
      return;
    }

    let { data, response } = res;
    if (data && data.success) {
      const user = data.data;
      updateUserDetail(user);
    }
    setLoader(false);
  };

  const handlePublish = async () => {
    if (user && user.isPublished) {
      enqueueSnackbar(
        "Your website's already live and stealing the show. No need to hit publish again.",
        { variant: "success" }
      );
      return;
    }
    setLoader(true);
    let res = await axiosInstance.put(
      APIRouteConstants.WEBSITE.PUBLISH_WEBSITE,
      {
        is_Published: true,
      }
    );

    if (res === false) {
      setLoader(false);
      return;
    }

    let { data, response } = res;

    if (!_.isEmpty(data)) {
      enqueueSnackbar(
        "Our chefs are busy preparing the website. Sit back and relax. It should be visible within a maximum of 15 minutes.",
        { variant: "success" }
      );
      getUser();
    }
  };
  console.log("loggedInUser", loggedInUser?.user?.websiteUrl);
  return (
    <CssVarsProvider theme={theme}>
      <VerifiyOtp />
      <Loader open={loader} />
      {/* {true &&( */}
      {/* )} */}
      <Box sx={{ display: "flex" }} className="dashboard-wrapper">
        <Box
          sx={{ display: { lg: "flex", md: "none", sm: "none", xs: "none" } }}
        >
          <SideNavigation
            appTitle={appTitle}
            isDisable={!user?.storeSetup}
            drawerWidth={drawerWidth}
            navItems={
              loggedInUser?.user?.websiteUrl === "thevetstore"
                ? sideMenuItemsforclient
                : sideMenuItems
            }
          />
        </Box>
        <Box
          sx={{ display: { lg: "none", md: "flex", sm: "flex", xs: "flex" } }}
        >
          <BottomAppBar
            navItems={
              loggedInUser?.user?.websiteUrl === "thevetstore"
                ? sideMenuItemsforclientBottom
                : sideMenuItemsBottom
            }
          />
        </Box>
        <Box
          component="main"
          className="right-content-wrapper"
          sx={{ flexGrow: 1, width: { lg: `calc(100% - ${drawerWidth}px)` } }}
        >
          <AppBar
            sx={{
              flexGrow: 1,
              width: { lg: `calc(100% - ${drawerWidth}px)` },
            }}
            infoStatus={user?.isPublished || false}
            pages={[
              {
                title: "Vouchers",
                href: dashboardRouteConstants.VOUCHERS,
              },
              {
                title: "Orders",
                href: dashboardRouteConstants.ORDERS,
              },
              {
                title: "Sales Report",
                href: dashboardRouteConstants.SALESREPORT,
              },
              {
                title: "Update Store",
                href: dashboardRouteConstants.UpdateStore,
                // icon: <HomeIcon />,
              },
              {
                title: "Customers",
                href: dashboardRouteConstants.CUSTOMERS,
              },
              {
                title: "Required checklist",
                href: "checklist",
              },
              // {
              //   title: "Shipping option",
              //   href: dashboardRouteConstants.SHIPPING,
              // }, 
            ]}
            rightAlignedPages={[
              {
                title: "Upgrade",
                href: dashboardRouteConstants.BILLING_DETAIL,
              },

            ]}
            actionItems={[{
              title: "Tutorial",
              href: "/dashboard/tutorial"
            }]}
            showTitle={false}
            appTitle={APP_NAME}
            logo={APP_LOGO}
            progress={setupProgress}
            published={user?.isPublished}
            handlePublish={handlePublish}
            user={true}
            websiteUrl={user?.websiteUrl}
          />
          <Box className="content-wrapper">{props.children}</Box>
          <SecondaryBar
            color="transparent"
            className="empty-appbar"
          ></SecondaryBar>
        </Box>
      </Box>
    </CssVarsProvider>
  );
};

export default DashboardLayout;

import { useState, useEffect } from 'react';

import {
    IconButton
} from '@mui/material';
import _ from 'lodash';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const ImageListPreview = ({ images, handleDeleteImage, isDisabled }) => {
    return (
        <div className='image-preview-wrapper'>
            {images.length ? images.map((image, i) => 
                <ImagePreview isDisabled={isDisabled} key={i} image={image} handleDeleteImage={handleDeleteImage} /> ) : '' }
        </div>
    )
}

const ImagePreview = ({ image, handleDeleteImage, isDisabled}) => {
    const [displayCancel, setDisplayCancel] = useState(false);

    return (
        <div className='img-wrapper' 
            onMouseEnter={e => {
                setDisplayCancel(true);
            }}
            onMouseLeave={e => {
                setDisplayCancel(false);
            }}
        >
            <div className='img-container'>
                <img src={typeof image == 'string' ? image : image?.blob} />
                {displayCancel && 
                    <IconButton 
                        aria-label="delete" 
                        size="small" 
                        disabled={isDisabled}
                        onClick={() => handleDeleteImage(image)}
                        className='cancel-btn'>
                        <CancelIcon fontSize="inherit" color="error" />
                    </IconButton> 
                }
            </div>
            <span className='preview-text'>
                {displayCancel ? _.truncate(image && image?.fileName, { length: 15 }) : <span className='span-hidden'>Hideen</span> }
            </span>
        </div>  
    )
}

const ImageUploader = ({ 
    imageList = [],
    setImageList,
    multiple = false,
    id = 'img',
    name = 'img',
    isDisabled=false,
    message
}) => {
    const [previewImageList, setPreviewImageList] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);

    const setURL = (files) => {
        for (let index in files) {
            let file = files[index]

            if (typeof file == 'object') {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                
                setImageList(imageList => [...imageList, file])

                reader.onload = (e) => {
                    let fileObj = {}
                    fileObj['fileName'] = file?.name
                    fileObj['key'] = previewImageList.length + +index + 1
                    fileObj['blob'] = reader.result
                    setPreviewImageList(images => [...images, fileObj]);
                };
            }
        }
    };

    useEffect(() => {
        if (initialLoad && !_.isEmpty(imageList)) {
            setPreviewImageList(_.map(imageList, i => {
                if (typeof i == 'object') return i.image
                else return i
            }))
            setInitialLoad(false);
        }
    }, [imageList])

    const handleDeleteImage = (image) => {
        if (!multiple) {
            setImageList([])
            setPreviewImageList([])
            return;
        }
        setPreviewImageList(_.filter(previewImageList, i => i != image))
        setImageList(_.filter(imageList, i => i.image != image))
    }

    return (
        <div className="image-uploader-wrapper">
            <div className='uploader-wrapper'>
                <div className='image-list-wrapper'>
                    {!previewImageList.length ? <div className='input-wrapper'>
                        <AddCircleIcon onClick={() => {
                            document.getElementById(id).click();
                        }} />
                        <span className='uploader-text'>Drop a JPG/PNG image to upload or browse</span>
                        <span className='uploader-sec-text'>{message ?`( ${message} )`: `(minimum 300 x 300 pixels)`}</span>
                    </div> : ''}
                    {previewImageList.length ? 
                        <div className='preview-wrapper'>
                            <ImageListPreview 
                                images={previewImageList} 
                                isDisabled={isDisabled}
                                handleDeleteImage={handleDeleteImage} />
                            { multiple ?
                            <div className='add-btn-wrapper'>
                                <AddCircleIcon onClick={() => {
                                    document.getElementById(id).click();
                                }} />
                            </div> : '' }
                        </div>
                    : ''}
                </div>
            </div>
            <div className='image-wrapper'>
                <input 
                    type="file" 
                    multiple={multiple}
                    onChange={(event) => {
                        setInitialLoad(false)
                        setURL(event.target.files)
                        event.target.value = ''
                    }}
                    id={id} 
                    name={name} 
                    hidden 
                    accept="image/*" />
            </div>
        </div>
    )
}

export default ImageUploader;



export const ImageUploader1 = ({ 
    message,
    imageList = [],
    setImageList,
    multiple = false,
    id = 'img',
    name = 'img',
    isDisabled = false,
    replaceImage // New prop to trigger the image replacement
}) => {
    const [previewImageList, setPreviewImageList] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);

    const setURL = (files) => {
        if (!multiple) {
            // Clear existing image if not allowing multiple uploads
            setImageList([]);
            setPreviewImageList([]);
        }

        for (let index in files) {
            let file = files[index];

            if (typeof file === 'object') {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                
                setImageList(imageList => [...imageList, file]);

                reader.onload = () => {
                    let fileObj = {
                        fileName: file?.name,
                        key: previewImageList.length + +index + 1,
                        blob: reader.result
                    };
                    setPreviewImageList(images => [...images, fileObj]);
                };
            }
        }
    };

    useEffect(() => {
        if (initialLoad && imageList.length > 0) {
            setPreviewImageList(imageList.map(i => {
                return typeof i === 'object' ? i.image : i;
            }));
            setInitialLoad(false);
        }
    }, [imageList, initialLoad]);

    const handleDeleteImage = (image) => {
        setImageList([]);
        setPreviewImageList([]);
    };

    // This useEffect listens to the `replaceImage` function and triggers file input
    useEffect(() => {
        if (replaceImage) {
            replaceImage(() => {
                document.getElementById(id).click();
            });
        }
    }, [replaceImage, id]);

    return (
        <div className="image-uploader-wrapper">
            <div className='uploader-wrapper'>
                <div className='image-list-wrapper'>
                    {!previewImageList.length ? 
                        <div className='input-wrapper'>
                            <AddCircleIcon onClick={() => {
                                document.getElementById(id).click();
                            }} />
                            <span className='uploader-text'>Drop a JPG/PNG image to upload or browse</span>
                            <span className='uploader-sec-text'>{message?`( ${message} )`: `(minimum 300 x 300 pixels)`}</span>
                        </div> 
                    : ''}

                    {previewImageList.length ? 
                        <div className='preview-wrapper'>
                            <ImageListPreview 
                                images={previewImageList} 
                                isDisabled={isDisabled}
                                handleDeleteImage={handleDeleteImage} />
                            {/* <div className='add-btn-wrapper'>
                                <button onClick={() => document.getElementById(id).click()}>
                                    Select New Image
                                </button>
                            </div> */}
                        </div>
                    : ''}
                </div>
            </div>
            <div className='image-wrapper'>
                <input 
                    type="file" 
                    multiple={multiple}
                    onChange={(event) => {
                        setInitialLoad(false);
                        setURL(event.target.files);
                        event.target.value = '';
                    }}
                    id={id} 
                    name={name} 
                    hidden 
                    accept="image/*" 
                />
            </div>
        </div>
    );
};


import { authRouteConstants } from "constants/routeConstants"
import { useEffect } from "react"

const Logout = () => {
    const doLogout = () => {
        localStorage.removeItem('access-token')
        localStorage.removeItem('refresh-token')
        localStorage.clear()
        window.location.href = authRouteConstants.HOME;
    }

    useEffect(() => {
        doLogout();
    }, [])
}

export default Logout